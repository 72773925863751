import keyMirror from './keyMirror';

export const eventTopics = keyMirror({
  TOGGLE_MODAL: null,
  SET_NEW_USER_EVENT: null,
  UPDATE_ANIMATE_EVENT: null,
  FILTER_RESIDENTS_LIST: null,
  CHAT_BUBBLES_DISMISSED: null,
  MODERATION_FAILED_EVENT: null,
  RESET_RESIDENTS_FILTERS: null,
  SET_RESIDENT_AND_MESSAGE: null,
  SET_MESSENGER_INPUT_ERROR: null,
  REMOVE_RESIDENT_AND_MESSAGE: null,
  TOGGLE_RESIDENTS_FILTER_DRAWER: null,
});

export const storageKeys = keyMirror({
  __RG_USER__: null,
  __RG_WIDGET_ERROR__: null,
  __RG_ANIMATED_COUNT__: null,
  __EXPECTED_MOVE_IN_DATE__: null,
  __RG_WIDGET_RENDER_PROPS__: null,
  __RG_WIDGET_RENDER_COUNT__: null,
  __RG_CHAT_BUBBLES_IS_DISMISSED__: null,
});

export const demoWidgetKeys = {
  production: [`${process.env.DEMO_WIDGET_KEY}`],
  staging: [`${process.env.DEMO_WIDGET_KEY}`],
  development: [process.env.DEMO_WIDGET_KEY],
};

export const analyticsEventActions = {
  user_types_message: 'user_types_message',
  user_types_name: 'user_types_name',
  user_types_email: 'user_types_email',

  user_types_lastName: 'user_types_lastName',
  user_types_firstName: 'user_types_firstName',
  user_types_phone: 'user_types_phone',
  user_types_moveInDate: 'user_types_moveInDate',
  existing_user_types_password: 'existing_user_types_password',
  user_clicks_send_button: 'user_clicks_send_button',
  user_clicks_contact_property_button: 'user_clicks_contact_property_button',
  user_opens_contact_property_form: 'user_opens_contact_property_form',

  user_sends_messages_succeeds: 'user_sends_messages_succeeds',
  user_sends_messages_fails: 'user_sends_messages_fails',

  user_successfully_connects_with_facebook:
    'user_successfully_connects_with_facebook',
  user_views_continue_with_facebook: 'user_views_continue_with_facebook',
  existng_user_submits_password: 'existng_user_submits_password',

  user_minimizes_widget: 'user_minimizes_widget',
  user_expands_widget: 'user_expands_widget',
  user_dismisses_bubbles: 'user_dismisses_bubbles',

  user_views_password: 'user_views_password',
  user_cancels_verification: 'user_cancels_verification',
  user_resends_verification: 'user_resends_verification',

  user_applies_floor_plans_filter: 'user_applies_floor_plans_filter',
  user_applies_pets_filter: 'user_applies_pets_filter',
  user_applies_more_filter: 'user_applies_floorplan_filter',

  user_resets_resident_filter: 'user_resets_resident_filter',
  first_prospect_convo_at_listing: 'first_prospect_convo_at_listing',
  prospect_opted_out_of_auto_guest_card:
    'prospect_opted_out_of_auto_guest_card',

  opens_contact_property: 'opens_contact_property',
  opens_chat_with_resident: 'opens_chat_with_resident',
  opens_schedule_tour: 'opens_schedule_tour',
  shows_more_features: 'shows_more_features',
  shows_less_features: 'shows_less_features',

  opens_knock_contact_property: 'opens_knock_contact_property',
  opens_knock_neighborhood: 'opens_knock_neighborhood',
  opens_knock_photos: 'opens_knock_photos',
  opens_knock_prices_avail: 'opens_knock_prices_avail',
  opens_knock_request_text: 'opens_knock_request_text',
  opens_knock_schedule_tour: 'opens_knock_schedule_tour',

  opens_more_page_via_more_icon: 'opens_more_page_via_more_icon',
  opens_better_bot: 'opens_better_bot',

  loads_compact_view: 'loads_compact_view',

  close_resident_list: 'close_resident_list',
  selectsResidentFilter: 'selects_resident_filter',
  editMessage: 'edit_message',
  predefinedMessage: 'predefined_message',
  signIn: 'sign_in',
  rewardLearnMore: 'reward_learn_more',
  closeChatRoom: 'close_chat',
  clickTermsLink: 'terms_link',
  clickPrivacyLink: 'privacy_policy_link',
  goToConversation: 'go_to_conversation',
};

export const analyticsEventCategory = {
  message_form: 'message_form',
  resident_filter: 'resident_filter',
  contact_property_form: 'contact_property_form',
  widget_open: 'widget_open',
  host: 'host',
  origin: 'origin',
  authentication: 'authentication',
  conversation: 'conversation',
  guest_card_automation: 'guest_card_automation',
  rg_link: 'rg_messenger_link',
  rg_widget: 'rg_widget',
  compact: 'compact',
  chatRoom: 'chat_room',
  residentList: 'resident_list',
  moreList: 'more_list',
  footer: 'footer',
};

export const analyticsEventLabels = {
  feature_icon: 'feature_icon',
  feature_button: 'feature_button',
  carat: 'carat',
};

export const thirdPartyAnalyticsPages = {
  '/': true,
  '/contact-property-success': true,
  '/resident-list': true,
  '/schedule-tour': true,
};

export const thirdPartyAnalyticsEvents = {
  opens_knock_contact_property: true,
  opens_knock_neighborhood: true,
  opens_knock_photos: true,
  opens_knock_prices_avail: true,
  opens_knock_request_text: true,
  opens_knock_schedule_tour: true,

  opens_contact_property: true,
  opens_chat_with_resident: true,
  opens_schedule_tour: true,

  user_sends_messages_succeeds: true,
  user_opens_contact_property_form: true,

  opens_better_bot: true,
};

export const thirdPartyAnalyticsDecodeAction = {
  '/': 'rendered',
  '/contact-property-success': 'completion',
  '/resident-list': 'rendered',
  '/schedule-tour': 'launch',
  opens_knock_contact_property: 'launch',
  opens_knock_neighborhood: 'launch',
  opens_knock_photos: 'launch',
  opens_knock_prices_avail: 'launch',
  opens_knock_request_text: 'launch',
  opens_knock_schedule_tour: 'launch',
  opens_contact_property: 'launch',
  opens_chat_with_resident: 'launch',
  opens_schedule_tour: 'launch',
  user_sends_messages_succeeds: 'completion',
  user_opens_contact_property_form: 'launch',
  opens_better_bot: 'launch',
};

/* -----> Max:
  There appears to be an issue where the schedule tour flow does not have a corresponding
  completion action. Therefore, making a hot change to send contact property instead of
  schedule tour since we aren't able to distinguish those completions and it's
  causing contact property to have a higher conversion rate than it should.
*/

export const thirdPartyAnalyticsDecodeLabel = {
  '/': 'compact widget',
  '/contact-property-success': 'contact property',
  '/resident-list': 'chat with a resident',
  '/schedule-tour': 'contact property',
  opens_knock_contact_property: 'knock contact property',
  opens_knock_neighborhood: 'knock neighborhood',
  opens_knock_photos: 'knock photos',
  opens_knock_prices_avail: 'knock prices available',
  opens_knock_request_text: 'knock request text',
  opens_knock_schedule_tour: 'knock schedule tour',
  opens_contact_property: 'contact property',
  opens_chat_with_resident: 'chat with a resident',
  opens_schedule_tour: 'contact property',
  user_sends_messages_succeeds: 'chat with a resident',
  user_opens_contact_property_form: 'contact property',
  opens_better_bot: 'better bot',
};

export const modalIDs = {
  MORE_FEATURES_MODAL: 'MORE_FEATURES_MODAL',
  CONTACT_PROPERTY_MODAL: 'CONTACT_PROPERTY_MODAL',
  SCHEDULE_A_TOUR: 'SCHEDULE_A_TOUR',
  POWERED_BY_RENTGRATA_MODAL: 'POWERED_BY_RENTGRATA_MODAL',
  NEW_USER_MODAL: 'NEW_USER_MODAL',
  PASSWORD_REQUIRED_MODAL: 'PASSWORD_REQUIRED_MODAL',
};

export const drawerIDs = keyMirror({
  FLOOR_PLANS: null,
  PETS: null,
  MORE: null,
});

export const cssPrefix = 'rentgrata-widget-2rByT2ARyT-';

const defaultExport = {
  eventTopics,
  storageKeys,
  demoWidgetKeys,
  analyticsEventActions,
  analyticsEventCategory,
  modalIDs,
  drawerIDs,
  cssPrefix,
  analyticsEventLabels,
};

export default defaultExport;
