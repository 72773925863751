import sortBy from 'lodash/sortBy';
import dayjs from 'dayjs';
import env from './env';
import { cssPrefix } from './constants';
import { useLocation } from 'react-router-dom';
import { isDedicated } from '../../shared/styles';
import { bugsnag } from '../../shared/bugsnag';
import { isKnockIntegrated } from '../../shared/integrations/utils';

const { environment } = env;

export function size(obj) {
  return obj && Object.keys(obj).length;
}

export function capitalize(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

export function isEmpty(obj) {
  return size(obj) < 1;
}

const tourTypesObj = {
  in_person_tour: 'In person tour',
  live_video_tour: 'Live video tour',
  self_guided_tour: 'Self guided tour',
  AGENT_GUIDED: 'Agent guided',
  SELF_GUIDED: 'Self guided',
  VIRTUAL_GUIDED: 'Virtual guided',
};

export function getTourTypeLabel(type) {
  return tourTypesObj[type];
}

export function getKeyFromTourTypeLabel(label) {
  return Object.keys(tourTypesObj).find((key) => tourTypesObj[key] === label);
}

export async function asyncHandler(promise) {
  return promise
    .then((resp) => {
      return {
        ok: true,
        data: resp.data,
      };
    })
    .catch((e) => {
      return {
        ok: false,
        error: e.response,
      };
    });
}

export function getValue(str, dict) {
  let keys = str.split(/[\[|\]|.]/).filter((key) => key);

  const result = keys.reduce((obj, key) => {
    return obj && obj[key] ? obj[key] : undefined;
  }, dict);

  return result;
}

export function isTouchDevice() {
  return 'ontouchstart' in document.documentElement;
}

export function errorHandler(error) {
  if (environment === 'production') {
    bugsnag.notify(new Error(error));
  }

  throw new Error(error);
}

export function formatPropertyPayload(payload) {
  const {
    firstName: first_name,
    lastName: last_name,
    email: current_user_email,
    phone: phone_number,
    moveInDate: move_in_date,
    tourDate: tour_date,
    tourTime: tour_time,
    tourMonth: month,
    tourYear: year,
    tourTypes: tour_types,
    tourType: tour_type,
    displayTourTypes: display_tour_types,
    trackerCookieValue: tracker_cookie_value,
    widgetRenderedAs: widget_rendered_as,
    message,
  } = payload;
  return {
    first_name,
    last_name,
    current_user_email,
    phone_number,
    move_in_date,
    tour_date,
    tour_time,
    month,
    year,
    tour_types,
    tour_type,
    display_tour_types,
    message,
    tracker_cookie_value,
    widget_rendered_as,
    via_widget: true,
  };
}

export function transformFloorPlansOptions(options) {
  return options.map((option) => {
    if (option === 'Studio') {
      return 0;
    }
    return Number.parseInt(option);
  });
}

export function transformPetsAndMoreOptions(options) {
  return options.map((option) => option.replace(' ', '_').toLowerCase());
}

export function transformAvailableFloorPlansOptions(options = []) {
  return options.map((floorPlan) => {
    if (typeof floorPlan === 'number' && floorPlan === 0) {
      return 'Studio';
    }
    if (typeof floorPlan === 'number' && floorPlan < 4) {
      return `${floorPlan} Bedrooms`;
    }
    if (typeof floorPlan === 'number' && floorPlan >= 4) {
      return `${floorPlan}+ Bedrooms`;
    }
    return `${floorPlan} Bedroom`;
  });
}

export function transformAvailablePetsAndMoreOptions(options = []) {
  return options.map((option) => {
    return option
      .split('_')
      .map((word) => capitalize(word))
      .join(' ');
  });
}

export function prefixCssClassName(className) {
  return `${cssPrefix}${className}`;
}

export function transformFieldName(fieldName, residentId) {
  return fieldName.replace(`-${residentId}`, '');
}

export function genFieldNames(residentId) {
  return {
    firstNameField: `firstName-${residentId}`,
    lastNameField: `lastName-${residentId}`,
    messageField: `message-${residentId}`,
    loginTokenField: `loginToken-${residentId}`,
    emailField: `email-${residentId}`,
    expectedMoveInDateField: `expectedMoveInDate-${residentId}`,
  };
}

export const retryFn = (fn, retriesLeft = 7, interval = 1000) =>
  new Promise((resolve, reject) => {
    fn()
      .then(resolve)
      .catch((error) => {
        setTimeout(() => {
          if (retriesLeft === 1) {
            return reject(error);
          }
          retryFn(fn, retriesLeft - 1, interval).then(resolve, reject);
        });
      });
  });

export function handleButtonKeyPress(fn) {
  return function (event) {
    if (event.keyCode === 13 || event.keyCode === 32) {
      fn.apply(this, arguments);
    }
  };
}

export function handleSpaceKeyPress(fn) {
  return function (event) {
    if (event.keyCode === 32) {
      fn.apply(this, arguments);
    }
  };
}

export function genCompareInputFeedbackValues() {
  let inputFeedback = {};

  return function (currInputFeedback = {}) {
    let isUpdated = false;

    if (!Object.keys(currInputFeedback).length) return false;

    for (const key in Object.keys(currInputFeedback)) {
      if (inputFeedback[key] !== currInputFeedback[key]) {
        isUpdated = true;
        break;
      }
    }
    inputFeedback = currInputFeedback;

    return isUpdated;
  };
}

export function isFireFox() {
  return typeof InstallTrigger !== 'undefined';
}

export function isIE() {
  return false || !!document.documentMode;
}

export function throttle(fn, { delay, condition }) {
  let setTimeoutId;

  return function () {
    if (setTimeoutId || condition) {
      return;
    }
    setTimeoutId = setTimeout(() => {
      fn.apply(this, arguments);
      setTimeoutId = null;
    }, delay);
  };
}

export function useQuery() {
  return new URLSearchParams(useLocation().search);
}

export function sleep(ms) {
  return new Promise((resolve) => setTimeout(resolve, ms));
}

export function range(count) {
  return Array.from(Array(count)).map((_, i) => i);
}

export function sortByResidentImages(residents) {
  const result = [];

  if (!residents || !Array.isArray(residents)) return result;

  return sortBy(residents, [(resident) => !resident.image_url]);
}

export function sizeContent(selector, headSelector) {
  const contentSelector = selector || '.rg-layout-content';
  const headerSelector = headSelector || '.ant-layout-header';
  const header = document.querySelector(headerSelector);
  const footer = document.querySelector('.footerWrapper');
  const content = document.querySelector(contentSelector);

  if (!content) return;

  content.style.height = `${
    window.innerHeight -
    ((header.offsetHeight || 0) + (footer?.offsetHeight || 0))
  }px`;
}

export const formatDate = (date) => {
  return dayjs(date).format('MM/DD/YYYY');
};

export const isTourDateBeforeMoveInDate = (tourDate, moveInDate) => {
  const hasMissingDate = !tourDate || !moveInDate;

  if (hasMissingDate) return !hasMissingDate;

  return new Date(tourDate).getTime() >= new Date(moveInDate).getTime();
};

export const onEscapeClose = () => {
  document.addEventListener('keydown', (event) => {
    if (
      (event.key === 'Escape' ||
        event.code === 'Escape' ||
        event.keyCode === 29) &&
      !isDedicated(window?.xprops?.renderAs)
    ) {
      window.xprops.onToggleMessenger();
    }
  });
};

export function stripTrailingSlash(str) {
  if (str.substr(-1) === '/') {
    return str.substr(0, str.length - 1);
  }

  return str;
}

export function canScheduleTour(listing, platform) {
  const hasKnock = isKnockIntegrated(listing);

  if (platform === 'mobile') {
    return hasKnock && listing.has_mobile_knock_schedule_tour;
  }

  if (platform === 'desktop') {
    return hasKnock && listing.has_desktop_knock_schedule_tour;
  }

  return hasKnock && listing.knock_schedule_tour;
}

export function getActiveServices(services, integrations, platform) {
  const activeServiceNames = new Set();

  for (const integration of Object.values(integrations)) {
    const serviceData = integration?.services?.[platform];

    if (serviceData) {
      for (const serviceName of Object.keys(serviceData)) {
        if (serviceData[serviceName]) {
          activeServiceNames.add(serviceName);
        }
      }
    }
  }

  const activeServices = services.filter((service) =>
    activeServiceNames.has(service.service)
  );

  return activeServices;
}

const defaultExport = {
  size,
  capitalize,
  isEmpty,
  asyncHandler,
  getValue,
  isTouchDevice,
  errorHandler,
  formatPropertyPayload,
  transformFloorPlansOptions,
  transformPetsAndMoreOptions,
  transformAvailableFloorPlansOptions,
  transformAvailablePetsAndMoreOptions,
  prefixCssClassName,
  transformFieldName,
  genFieldNames,
  retryFn,
  handleButtonKeyPress,
  handleSpaceKeyPress,
  genCompareInputFeedbackValues,
  isFireFox,
  isIE,
  throttle,
  useQuery,
  sleep,
  range,
  sortByResidentImages,
  sizeContent,
  formatDate,
  isTourDateBeforeMoveInDate,
  stripTrailingSlash,
  canScheduleTour,
  getActiveServices,
};

export default defaultExport;
