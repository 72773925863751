import retry from 'async/retry';
import { IListing } from '../../@types/listing';
import { injectScript } from '../injectScript';

import { RequestObserver } from '../request-observer';

export const knockMobileKeys: string[] = [
  'has_mobile_knock_contact_property',
  'has_mobile_knock_neighborhood',
  'has_mobile_knock_photos',
  'has_mobile_knock_prices_avail',
  'has_mobile_knock_request_text',
  'has_mobile_knock_schedule_tour',
];

export const knockDesktopKeys: string[] = [
  'has_desktop_knock_contact_property',
  'has_desktop_knock_neighborhood',
  'has_desktop_knock_photos',
  'has_desktop_knock_prices_avail',
  'has_desktop_knock_request_text',
  'has_desktop_knock_schedule_tour',
];

export const knockMorePageKeys: string[] = [
  'knock_contact_property',
  'knock_schedule_tour',
  'knock_prices_avail',
  'knock_photos',
  'knock_neighborhood',
  'knock_request_text',
];

export const knockFeatureKeys: string[] = [
  ...knockMobileKeys,
  ...knockDesktopKeys,
  ...knockMorePageKeys,
];

class KnockDoor {
  styleId: string;
  url: string;
  ownHosts: string[];
  listing: IListing;
  isReady: boolean;

  constructor() {
    this.styleId = 'knock-door-rentgrata-integration';
    this.url = 'https://doorway.knck.io/latest/doorway.min.js';
    this.ownHosts = [
      `localhost:${process.env.WIDGET_PORT}`,
      'rentgrata-messenger-dev.herokuapp.com',
      'https://widget.rentgrata.com',
    ];

    //    new RequestObserver([
    //      {
    //        path: 'doorway-api.knockrentals.com/v1/profile/contact-information',
    //        onSuccess: response => console.log('Endpoint analytics success:', response),
    //        onError: error => console.log('Endpoint analytics error:', error),
    //      },
    //    ]);
  }

  processListing(listing: IListing) {
    const hasKeys = Boolean(
      listing.knock_init_key && listing.knock_community_id
    );

    if (hasKeys && this.hasAtLeastOneFeature(listing)) {
      this.listing = listing;
      this.overrideKnockDoorStyles(listing.hide_knock_widget);
    }
  }

  async injectKnockDoor(action: string) {
    await injectScript(knockdoor.url, 'rg-knock');

    window.knockDoorway.init(
      this.listing.knock_init_key,
      'community',
      this.listing.knock_community_id
    );

    window.knockDoorway.inject();
    this.watchKnock(action);
  }

  async triggerKnockFeature(action: string) {
    if (this.listing && !this.isReady) {
      await this.injectKnockDoor(action);
    } else if (this.listing && this.isReady) {
      window.knockDoorway[action]();
    }
  }

  watchKnock(action: string) {
    const handler = (callback) => {
      const isReady = typeof window.knockDoorway?.openContact === 'function';
      const error = !isReady ? new Error('knockDoorway is not ready') : null;

      callback(error, isReady);
    };

    retry({ times: 10, interval: 500 }, handler, (error, result) => {
      this.isReady = result;

      if (!result) {
        window.knockDoorway.inject();
        this.watchKnock(action);
      } else {
        this.overrideKnockDoorStyles(this.listing?.hide_knock_widget);
        window.knockDoorway?.[action]();
      }
    });
  }

  getIsReady = () => this.isReady;

  hasAtLeastOneFeature(listing: IListing) {
    return !!knockFeatureKeys.find((key) => !!listing[key]);
  }

  openScheduleTour = async () => {
    await this.triggerKnockFeature('openScheduling');
  };

  openPhotos = async () => {
    await this.triggerKnockFeature('openPhotos');
  };

  openContactProperties = async () => {
    await this.triggerKnockFeature('openContact');
  };

  openNeighborhood = async () => {
    await this.triggerKnockFeature('openNeighborhood');
  };

  openAvailabilities = async () => {
    await this.triggerKnockFeature('openAvailabilities');
  };

  openTextWithUs = async () => {
    await this.triggerKnockFeature('openTextWithUs');
  };

  overrideKnockDoorStyles(shouldHide?: boolean) {
    if (!shouldHide) return;

    const style = `
      .doorway-launcher-button-wrapper {
        display: none;
      }

      #knockDoorwayRoot {
        z-index: 100000000 !important;
      }

      .doorway-plugin-frame-wrapper {
        z-index: 100000000 !important;
      }

      .doorway-notification-frame-wrapper.doorway-notification-frame-wrapper-shown {
        display: none;
      }
    `;

    let styleElement = document.getElementById(this.styleId);

    if (!styleElement) {
      styleElement = document.createElement('style');

      styleElement.setAttribute('type', 'text/css');
      styleElement.setAttribute('id', this.styleId);

      document.body.appendChild(styleElement);
    }

    styleElement.innerText = style;
  }
}

const knockdoor = new KnockDoor();

export { knockdoor };
