import { IListing } from '../../@types/listing';
import { isFixed } from '../styles';

export const isKnockIntegrated = (listing: IListing) => {
  return !!(listing?.knock_community_id && listing?.knock_init_key);
};

export const isBetterBotIntegrated = (listing: IListing) =>
  !!listing?.betterbot_subdomain;

export const removeFirstResidentDuplicate = (
  residents: Record<string, any>[]
) => {
  const results = [];
  const history = {};

  for (let i = 0; i <= residents.length - 1; i++) {
    if (!history[residents[i]?.id] && residents[i]?.id) {
      results.push(residents[i]);
    }

    history[residents[i]?.id] = true;
  }

  return results;
};

export const useCompact = (listing: IListing, config: any) => {
  if (!isFixed(config.renderAs)) {
    return false;
  }

  if (isKnockIntegrated(listing) || isBetterBotIntegrated(listing)) {
    return listing.smaller_messenger_design;
  }

  return true;
};
